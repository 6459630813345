import { Spinner } from '../../../../styles/icons';
import { classNames } from '../../../../styles/utils';
import { handleScrollTop } from '../utils';

const Action = ({
  openTab,
  setOpenTab,
  isLoading,
  handleSubmitContract,
  canClearSignature = false,
  onClearDigitalSignature,
  lastStepNumber,
}: {
  openTab: number | string;
  setOpenTab: (tabs: string | number) => void;
  isLoading?: boolean;
  canClearSignature?: boolean;

  handleSubmitContract: (values: any) => Promise<void>;
  onClearDigitalSignature: () => void;
  lastStepNumber: number;
}) => {
  const btnlabel = 'Senden';
  const btnClassName =
    'border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600';

  const handleNextStep = () => {
    //@ts-ignore
    setOpenTab((prev: number) => Number(prev) + 1);
    handleScrollTop('main-scroll-container');
    // // validateForm();
    // if (!isError) {

    //   // validateForm();
    // } else {
    //   setOpenTab(setTabNumberBasedOnValidation());
    // }
  };

  const handlePerv = () => {
    //@ts-ignore
    setOpenTab((prev: number) => Number(prev) - 1);
    handleScrollTop('main-scroll-container');
  };

  const isErrorIntheOpenedTab = !canClearSignature;

  return (
    <div className="py-3 text-right flex justify-between">
      <div className="order-first">
        {/* adding clear signature button when canClearSignature in true */}
        {canClearSignature ? (
          <button
            onClick={() => onClearDigitalSignature()}
            type="button"
            className=" bg-white border rounded-md shadow-sm py-2 px-6 inline-flex justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100">
            Neu
          </button>
        ) : openTab !== 0 ? (
          <button
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              handlePerv();
            }}
            type="button"
            className=" bg-white border rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100">
            Zurück
          </button>
        ) : null}
      </div>

      {Number(openTab) < lastStepNumber && (
        <div>
          <button
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              handleNextStep();
            }}
            disabled={isErrorIntheOpenedTab}
            type="button"
            className={`${btnClassName} ${
              isErrorIntheOpenedTab
                ? 'bg-gray-400'
                : 'bg-orange-500 hover:bg-orange-600'
            }`}>
            Weiter
          </button>
        </div>
      )}
      {openTab === lastStepNumber && (
        <button
          //  disabled={isSubmitting}
          //  type="submit"
          onClick={handleSubmitContract}
          className={classNames(
            !isLoading
              ? 'bg-orange-500 hover:bg-orange-600 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed',
            'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          )}
          // className="bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isLoading ? (
            <div className="flex items-center align-center">
              <span>{btnlabel}</span> <Spinner className="h-4 w-4 ml-2" />
            </div>
          ) : (
            `${btnlabel}`
          )}
        </button>
      )}
    </div>
  );
};

export default Action;
